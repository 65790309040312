import { Controller } from '@hotwired/stimulus'
import { type HTMLInputEvent } from '../types/html_event'

// Connects to data-controller="sensor-map-creator"
export default class extends Controller {
  static targets = ['mapImage', 'container', 'canvas', 'legend', 'sensorTemplate', 'sensors']

  readonly mapImageTarget!: HTMLImageElement
  readonly containerTarget!: HTMLDivElement
  readonly canvasTarget!: SVGElement
  readonly legendTarget!: SVGGElement
  readonly sensorTemplateTarget!: SVGGElement
  readonly sensorsTarget!: SVGGElement

  async loadImage (event: HTMLInputEvent): Promise<void> {
    const files = event.target.files
    if (files === null) return

    const file = files[0]

    const reader = new FileReader()
    reader.onload = this.setImageSource.bind(this)
    reader.readAsDataURL(file)
  }

  addSensor (): void {
    const newSensor = this.sensorTemplateTarget.firstElementChild?.cloneNode(true) as SVGGElement
    this.sensorsTarget.appendChild(newSensor)
    this.loadSensorEventListeners(newSensor)
  }

  loadSensorEventListeners (sensor: SVGGElement): void {
    Array.from(sensor.children).forEach((element) => {
      const el = element as SVGGElement
      el.addEventListener('mousedown', this.drag.bind(this))
      el.addEventListener('touchstart', this.drag.bind(this))
    })
  }

  setImageSource (event: ProgressEvent<FileReader>): void {
    if (event.target === null) return

    const result = event.target.result?.toString()
    if (result === undefined) return

    this.mapImageTarget.setAttribute('xlink:href', result)
    this.containerTarget.classList.remove('mapCreator-containerHidden')
    this.clearSensors()
    this.calculateSVGDimensions(result)
  }

  calculateSVGDimensions (image: string): void {
    const img = new Image()
    img.src = image
    img.onload = () => {
      const width = img.width
      const height = img.height
      this.mapImageTarget.setAttribute('width', width.toString())
      this.mapImageTarget.setAttribute('height', height.toString())
      this.canvasTarget.setAttribute('width', width.toString())
      this.canvasTarget.setAttribute('height', (height + 70).toString())

      const legendHalf = this.legendTarget.getBoundingClientRect().width / 2
      const imgHalf = width / 2
      const legendXPos = imgHalf - legendHalf
      this.legendTarget.style.transform = `translate(${legendXPos}px, ${height + 30}px)`

      img.remove()
    }
  }

  clearSensors (): void {
    const sensors = this.sensorsTarget.children
    Array.from(sensors).forEach(sensor => { sensor.remove() })
  }

  getPositionFromEvent (event: MouseEvent | TouchEvent): { clientX: number, clientY: number } {
    const clientX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX
    const clientY = event instanceof MouseEvent ? event.clientY : event.touches[0].clientY
    return { clientX, clientY }
  }

  drag (event: MouseEvent | TouchEvent): void {
    event.preventDefault()

    // SVG element being dragged
    const draggedElement = event.currentTarget as SVGGElement

    // The SVG group for the sensor
    const sensorElement = draggedElement.parentElement as HTMLElement

    const engagedCircle = sensorElement.getElementsByTagName('circle')[0]
    const sensorCircle = sensorElement.getElementsByTagName('circle')[1]

    const leftRect = sensorElement.getElementsByTagName('rect')[0]
    const rightRect = sensorElement.getElementsByTagName('rect')[1]
    const topRect = sensorElement.getElementsByTagName('rect')[2]
    const bottomRect = sensorElement.getElementsByTagName('rect')[3]

    const { clientX, clientY } = this.getPositionFromEvent(event)

    const mouseXOnDown = clientX + window.scrollX
    const mouseYOnDown = clientY + window.scrollY
    const initialRadius = parseFloat(engagedCircle.getAttribute('r') ?? '0')

    // To prevent the sensor from snapping to the cursor position, we need to store the offset of the cursor relative to the sensor
    const sensorOffsetX = (clientX - sensorElement.getBoundingClientRect().left) - initialRadius
    const sensorOffsetY = (clientY - sensorElement.getBoundingClientRect().top) - initialRadius

    // Calculate the X and Y position for the sensor, based on:
    // - the cursor postion
    // - the sensor offset
    // - the scroll offset
    // - the position of the SVG canvas
    const calculateSensorPostion = (event: MouseEvent | TouchEvent): { x: number, y: number } => {
      const { clientX, clientY } = this.getPositionFromEvent(event)
      const mouseX = clientX - sensorOffsetX + window.scrollX
      const mouseY = clientY - sensorOffsetY + window.scrollY
      // We cannot get the position of the SVG element, so get its parent div position
      const canvasX = this.canvasTarget.parentElement?.offsetLeft ?? 0
      const canvasY = this.canvasTarget.parentElement?.offsetTop ?? 0
      return {
        x: mouseX - canvasX,
        y: mouseY - canvasY
      }
    }

    const moveSensor = (event: MouseEvent | TouchEvent): void => {
      const actualPostions = calculateSensorPostion(event)

      engagedCircle.setAttribute('cx', actualPostions.x.toString())
      engagedCircle.setAttribute('cy', actualPostions.y.toString())
      sensorCircle.setAttribute('cx', actualPostions.x.toString())
      sensorCircle.setAttribute('cy', actualPostions.y.toString())

      moveResizeRects(actualPostions.x, actualPostions.y, initialRadius)
    }

    const dropSensor = (): void => {
      document.removeEventListener('mousemove', moveSensor)
      document.removeEventListener('mouseup', dropSensor)
      document.removeEventListener('touchmove', moveSensor)
      document.removeEventListener('touchend', dropSensor)
    }

    const resizeSensor = (event: MouseEvent | TouchEvent): void => {
      const { clientX, clientY } = this.getPositionFromEvent(event)
      const mouseX = clientX + window.scrollX
      const mouseY = clientY + window.scrollY

      const isHorizontal = draggedElement === leftRect || draggedElement === rightRect

      // Calculate the difference in pixels between the initial mouse position and the new mouse postion
      // When the sensor is dragged from the left or top the difference between the two are negative
      // so they need to be multiplied by the resize direction (-1 or 1).
      // Adding the difference in pixels to the initial radius of the circle gives us the new radius.
      let pixelDiff = 0
      if (isHorizontal) {
        pixelDiff = (mouseX - mouseXOnDown) * resizeDirection()
      } else {
        pixelDiff = (mouseY - mouseYOnDown) * resizeDirection()
      }

      const newRadius = initialRadius + pixelDiff

      if (newRadius > 27) {
        engagedCircle.setAttribute('r', newRadius.toString())

        const circleCx = parseFloat(engagedCircle.getAttribute('cx') ?? '0')
        const circleCy = parseFloat(engagedCircle.getAttribute('cy') ?? '0')
        moveResizeRects(circleCx, circleCy, newRadius)
      }
    }

    const stopResize = (): void => {
      document.removeEventListener('mousemove', resizeSensor)
      document.removeEventListener('mouseup', stopResize)
      document.removeEventListener('touchmove', resizeSensor)
      document.removeEventListener('touchend', stopResize)
    }

    // Either 0, 1 or -1
    // 1 - indicates that the cursor is dragged right or down
    // 0 - indicates that the sensor is not being resized
    // -1 - indicates that the cursor is dragged left or up
    const resizeDirection = (): number => {
      return parseFloat(draggedElement.dataset.direction ?? '0')
    }

    // Calculate the new edge positions of the engaged circle, and place the resize rects accordingly
    const moveResizeRects = (circleCx: number, circleCy: number, radius: number): void => {
      leftRect.setAttribute('x', (circleCx - radius - leftRect.width.baseVal.value / 2).toString())
      leftRect.setAttribute('y', (circleCy - leftRect.height.baseVal.value / 2).toString())

      rightRect.setAttribute('x', (circleCx + radius - rightRect.width.baseVal.value / 2).toString())
      rightRect.setAttribute('y', (circleCy - rightRect.height.baseVal.value / 2).toString())

      topRect.setAttribute('x', (circleCx - topRect.width.baseVal.value / 2).toString())
      topRect.setAttribute('y', (circleCy - radius - topRect.height.baseVal.value / 2).toString())

      bottomRect.setAttribute('x', (circleCx - bottomRect.width.baseVal.value / 2).toString())
      bottomRect.setAttribute('y', (circleCy + radius - bottomRect.height.baseVal.value / 2).toString())
    }

    // Detect whether the sensor is being resized or moved
    if (resizeDirection() !== 0) {
      document.addEventListener('mousemove', resizeSensor)
      document.addEventListener('mouseup', stopResize)
      document.addEventListener('touchmove', resizeSensor)
      document.addEventListener('touchend', stopResize)
    } else {
      document.addEventListener('mousemove', moveSensor)
      document.addEventListener('mouseup', dropSensor)
      document.addEventListener('touchmove', moveSensor)
      document.addEventListener('touchend', dropSensor)
    }
  }

  showHowTo (): void {
    const howToModalContent = document.getElementById('howToModalContent')
    if (howToModalContent !== null) {
      howToModalContent.classList.remove('mapCreator-slideOut')
      howToModalContent.classList.add('mapCreator-slideIn')
    }

    const howToModal = document.getElementById('howToModal')
    if (howToModal !== null) {
      howToModal.classList.remove('mapCreator-fadeOut')
      howToModal.classList.add('mapCreator-fadeIn')
    }
  }

  hideHowTo (event: MouseEvent): void {
    if (event.target !== event.currentTarget) { return }

    const howToModalContent = document.getElementById('howToModalContent')
    if (howToModalContent !== null) {
      howToModalContent.classList.remove('mapCreator-slideIn')
      howToModalContent.classList.add('mapCreator-slideOut')
    }

    const howToModal = document.getElementById('howToModal')
    if (howToModal !== null) {
      howToModal.classList.remove('mapCreator-fadeIn')
      howToModal.classList.add('mapCreator-fadeOut')
    }
  }
}
