import { getCSRFToken } from '../helpers/get_csrf_token'
import {
  ModuleRegistry,
  AllCommunityModule,
  createGrid,
  themeAlpine,
  themeBalham,
  themeQuartz,
  type GridOptions,
  type GridApi,
  type Theme,
  type ColDef,
  type ValueGetterParams,
  type ValueFormatterParams,
  type ICellRendererParams,
  type ChartRef,
  type CreateRangeChartParams
} from 'ag-grid-community'
import {
  AllEnterpriseModule,
  LicenseManager,
  IntegratedChartsModule
} from 'ag-grid-enterprise'
import { AgChartsEnterpriseModule } from 'ag-charts-enterprise'
import { Controller } from '@hotwired/stimulus'
import { getAlpha2Code, registerLocale } from 'i18n-iso-countries'
import enLocale from 'i18n-iso-countries/langs/en.json'
ModuleRegistry.registerModules(
  [
    AllCommunityModule,
    AllEnterpriseModule,
    IntegratedChartsModule.with(AgChartsEnterpriseModule)
  ]
)
LicenseManager.setLicenseKey('Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-076645}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Commit_Digital_Limited}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Exposure_Analytics}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Exposure_Analytics}_need_to_be_licensed___{Exposure_Analytics}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{6_February_2026}____[v3]_[0102]_MTc3MDMzNjAwMDAwMA==414a370f5e1eb1de9c4937d2d7e75b9e')
registerLocale(enLocale)

export default class extends Controller {
  static values = {
    updateReportUrl: String,
    columnDefs: String,
    rowData: String,
    gridOptions: String,
    initialState: String,
    chartState: String,
    layoutClass: String,
    theme: String
  }

  static targets = ['grid', 'chart', 'gridAndChartContainer', 'saveButton', 'themeSelect']

  declare readonly gridTarget: HTMLElement
  declare readonly chartTarget: HTMLElement
  declare readonly gridAndChartContainerTarget: HTMLElement
  declare readonly saveButtonTarget: HTMLButtonElement
  declare readonly themeSelectTarget: HTMLSelectElement
  declare readonly updateReportUrlValue: string
  declare readonly columnDefsValue: string
  declare readonly rowDataValue: string
  declare readonly gridOptionsValue: string
  declare readonly initialStateValue: string
  declare readonly chartStateValue: string
  declare readonly layoutClassValue: string
  declare readonly themeValue: string
  declare gridApi: GridApi
  declare chartRef: ChartRef | undefined
  declare currentLayoutClass: string
  declare currentTheme: string
  declare themes: Record<string, Theme>

  connect (): void {
    this.themes = {
      quartz: themeQuartz,
      balham: themeBalham,
      alpine: themeAlpine
    }
    this.currentTheme = this.themeValue
    this.currentLayoutClass = this.layoutClassValue

    const columnDefs = JSON.parse(this.columnDefsValue)
    this._addValueGetters(columnDefs)
    this._addValueFormatters(columnDefs)
    this._addCellRenderers(columnDefs)

    const gridOptions: GridOptions = {
      ...JSON.parse(this.gridOptionsValue),
      rowData: JSON.parse(this.rowDataValue),
      columnDefs,
      initialState: JSON.parse(this.initialStateValue),
      theme: this.themes[this.currentTheme],
      defaultColDef: {
        minWidth: 100,
        flex: 1,
        floatingFilter: true
      },
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent' },
          { statusPanel: 'agTotalRowCountComponent' },
          { statusPanel: 'agFilteredRowCountComponent' },
          { statusPanel: 'agSelectedRowCountComponent' },
          { statusPanel: 'agAggregationComponent' }
        ]
      },
      rowGroupPanelShow: 'always',
      sideBar: true,
      enableCharts: true,
      cellSelection: true,
      onStateUpdated: () => { this._enableSaveButton() },
      popupParent: document.body,
      createChartContainer: (chartRef: ChartRef): void => { this._updateChart(chartRef) }
    }

    this.gridApi = createGrid(this.gridTarget, gridOptions)
    if (this.chartStateValue !== 'null') this.gridApi.createRangeChart(JSON.parse(this.chartStateValue))
  }

  disconnect (): void {
    this.gridApi.destroy()
  }

  setTheme (e: any): void {
    this.currentTheme = e.target.value
    this.gridApi.setGridOption('theme', this.themes[this.currentTheme])
  }

  async updateReport (): Promise<Response> {
    const body = {
      report: {
        initial_state: this.gridApi.getState(),
        chart_state: this._currentChartState(),
        theme: this.currentTheme,
        layout_class: this.currentLayoutClass
      }
    }

    this._disableSaveButton()

    return await fetch(this.updateReportUrlValue, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCSRFToken()
      },
      body: JSON.stringify(body)
    })
  }

  toggleLayout (): void {
    const columnLayoutClass = 'report-columnLayout'
    this.gridAndChartContainerTarget.classList.toggle(columnLayoutClass)
    this.currentLayoutClass = this.currentLayoutClass === columnLayoutClass ? '' : columnLayoutClass
  }

  _addValueGetters (columnDefs: ColDef[]): void {
    columnDefs.forEach((columnDef: ColDef) => {
      if (columnDef.valueGetter === 'unixToDate') {
        columnDef.valueGetter = ({ data }: ValueGetterParams) => data !== undefined ? new Date(data[columnDef.context] * 1000) : null
      }
    })
  }

  _addValueFormatters (columnDefs: ColDef[]): void {
    columnDefs.forEach((columnDef: ColDef) => {
      if (columnDef.valueFormatter === 'toLocaleDateString') {
        columnDef.valueFormatter = ({ value }: ValueFormatterParams) => value !== null ? value.toLocaleDateString() : null
      } else if (columnDef.valueFormatter === 'percentage') {
        columnDef.valueFormatter = ({ value }: ValueFormatterParams) => value !== null && value !== undefined ? `${value}%` : ''
      }
    })
  }

  _addCellRenderers (columnDefs: ColDef[]): void {
    columnDefs.forEach((columnDef: ColDef) => {
      if (columnDef.cellRenderer === 'countryWithFlag') {
        columnDef.cellRenderer = ({ value }: ICellRendererParams) => {
          const country: string | null = value
          if (country === null) return ''
          const alpha2Code = getAlpha2Code(country, 'en')?.toLocaleLowerCase()
          if (alpha2Code === undefined) return ''
          return `<span class="fi fi-${alpha2Code}"></span> ${country}`
        }
      }
    })
  }

  _updateChart (chartRef: ChartRef | undefined): void {
    if (this.chartRef !== undefined) this.chartRef.destroyChart()
    this.chartRef = chartRef
    this.chartTarget.innerHTML = ''
    if (this.chartRef === undefined) return
    const chartWrapperHTML = '<div class="report-chart-wrapper"><div class="report-chart-wrapper-body"></div></div>'
    this.chartTarget.insertAdjacentHTML('beforeend', chartWrapperHTML)
    const chartWrapper = this.chartTarget.lastElementChild as HTMLElement
    if (chartWrapper === undefined || chartWrapper === null) return
    chartWrapper.querySelector('.report-chart-wrapper-body')?.appendChild(this.chartRef.chartElement)
    this.chartRef.chartElement.scrollIntoView({ behavior: 'smooth' })
  }

  _currentChartState (): CreateRangeChartParams | undefined {
    const chartModels = this.gridApi.getChartModels()
    return chartModels !== undefined ? chartModels[0] : undefined
  }

  _enableSaveButton (): void {
    this.saveButtonTarget.disabled = false
  }

  _disableSaveButton (): void {
    this.saveButtonTarget.disabled = true
  }
}
