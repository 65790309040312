import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['totalVisitors', 'passing', 'engagedVisitors', 'dwell', 'impressions']
  declare readonly dwellTarget: HTMLElement
  declare readonly engagedVisitorsTarget: HTMLElement
  declare readonly passingTarget: HTMLElement
  declare readonly totalVisitorsTarget: HTMLElement
  declare readonly impressionsTarget: HTMLElement
  declare readonly hasTotalVisitorsTarget: boolean
  declare readonly hasDwellTarget: boolean
  declare readonly hasEngagedVisitorsTarget: boolean
  declare readonly hasPassingTarget: boolean
  declare readonly hasImpressionsTarget: boolean

  setHeroStatistics ({ detail }: any): void {
    if (this.hasTotalVisitorsTarget) {
      this.totalVisitorsTarget.innerHTML = this.formatStringNumber(detail.visitors_count)
    }
    if (this.hasDwellTarget) {
      this.dwellTarget.innerHTML = this.formatSeconds(detail.engaged_dwell)
    }
    if (this.hasEngagedVisitorsTarget) {
      this.engagedVisitorsTarget.innerHTML = this.formatStringNumber(detail.engaged_visitors_count)
    }
    if (this.hasPassingTarget) {
      this.passingTarget.innerHTML = this.formatStringNumber(detail.passing_visitors_count)
    }
    if (this.hasImpressionsTarget) {
      this.impressionsTarget.innerHTML = this.formatStringNumber(detail.impressions)
    }
  }

  formatSeconds (seconds: number): string {
    const formattedMinutes = String(Math.floor(seconds / 60)).padStart(2, '0')
    const formattedSeconds = String(seconds % 60).padStart(2, '0')
    return `${formattedMinutes}m ${formattedSeconds}s`
  }

  formatStringNumber (stringNumber: string): string {
    return parseInt(stringNumber).toLocaleString('en-GB')
  }
}
