import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="google-places"
export default class extends Controller {
  static targets = ['locationInput', 'timezoneInput', 'latitudeInput', 'longitudeInput', 'countryInput', 'mapCanvas']
  static values = { existingLatitude: Number, existingLongitude: Number }

  existingLatitudeValue!: number
  existingLongitudeValue!: number

  autocomplete!: google.maps.places.Autocomplete
  map!: google.maps.Map

  declare readonly locationInputTarget: HTMLInputElement
  declare readonly timezoneInputTarget: HTMLInputElement
  declare readonly latitudeInputTarget: HTMLInputElement
  declare readonly longitudeInputTarget: HTMLInputElement
  declare readonly countryInputTarget: HTMLInputElement
  declare readonly mapCanvasTarget: HTMLInputElement

  declare marker: google.maps.Marker

  advancedOptionsToggle: boolean = false

  async connect (): Promise<void> {
    const { Map } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary
    const { Autocomplete } = await google.maps.importLibrary('places') as google.maps.PlacesLibrary

    this.autocomplete = new Autocomplete(this.locationInputTarget)

    const defaultMapOptions: google.maps.MapOptions = {
      zoom: 2,
      center: { lat: 50.7963372, lng: -1.0659786 },
      gestureHandling: 'none'
    }
    this.map = new Map(this.mapCanvasTarget, defaultMapOptions)

    if (this.existingLatitudeValue !== 0 && this.existingLongitudeValue !== 0) {
      this.updateMap(this.existingLatitudeValue, this.existingLongitudeValue)
    }

    this.autocomplete.addListener('place_changed', async () => {
      const place = this.autocomplete.getPlace()
      if (place.geometry?.location === undefined) {
        return
      }

      const latitude: number = place.geometry.location.lat()
      const longitude: number = place.geometry.location.lng()
      let country: string = ''
      if (place.address_components !== undefined) {
        country = place.address_components.filter((addressComponent) => addressComponent.types[0] === 'country')[0].long_name
      }
      this.updateMap(latitude, longitude)

      const response = await fetch(`/api/timezones?latitude=${latitude}&longitude=${longitude}`)
      if (response.ok) {
        const json = await response.json()
        this.updateInterface(json.timezone, latitude, longitude, country)
      }
    })
  }

  updateInterface (timezone: string, latitude: number, longitude: number, country: string): void {
    this.timezoneInputTarget.value = timezone
    this.latitudeInputTarget.value = latitude.toString()
    this.longitudeInputTarget.value = longitude.toString()
    this.countryInputTarget.value = country
  }

  updateMap (latitude: number, longitude: number): void {
    const position = new google.maps.LatLng(latitude, longitude)
    if (this.marker === undefined) {
      this.marker = new google.maps.Marker({
        position,
        map: this.map
      })
    } else {
      this.marker.setPosition(position)
    }

    this.map.moveCamera({ center: position, zoom: 13 })
  }

  toggleAdvancedOptions (): void {
    if (this.advancedOptionsToggle) {
      this.latitudeInputTarget.required = false
      this.longitudeInputTarget.required = false

      this.latitudeInputTarget.disabled = true
      this.latitudeInputTarget.disabled = true
    } else {
      this.latitudeInputTarget.required = true
      this.longitudeInputTarget.required = true

      this.latitudeInputTarget.disabled = false
      this.latitudeInputTarget.disabled = false
    }
    this.advancedOptionsToggle = !this.advancedOptionsToggle
  }
}
